import React from 'react'
import { Form } from '../contact/Form'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'

export const ContactScreen = () => {
    return (
        <>
         <Header/>   
            <Form/>
         <Footer/>
        </>
    )
}
