 import React from 'react'
 import styled from 'styled-components'
import {Link} from 'react-router-dom';

const Ol = styled.ol`
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 1000px;
    align-items: center;

    li{
        font-size: 20px;
        padding: 18px 10px;
        margin-left: 20px; 
    }
    @media screen and (max-width: 835px) {
        z-index: 10;
        width: 300px;
        transform: ${({open}) => open ? 'translateX(0)' : 'translateX(100%)'};
        flex-flow: column nowrap;
        background-color:  #0000009a;
        position:fixed;
        top: 0;
        right: 0;
        transition: transform 1s ease-in-out; 
        
       a{
            color: white;
        }
  }
   

`


 export const SideBarData = ({open}) => {
     return (
        
             <Ol open={open} className={styled.nav__list}>
                <li><Link to="/">Inicio</Link></li>
                <li><Link to="/cursos">Cursos</Link> </li>
                <li><Link to="/about">A&L English Institute</Link> </li>
                <li><Link to="/contact">Contacto</Link> </li>
            </Ol>
         
     )
 }
 