import React from 'react'

import { CarouselContainer } from '../carousel/CarouselContainer'
import styled from '../header/header.module.css'
import { Header } from '../header/Header'
import { Footer } from '../footer/Footer'


export const InicioScreen = () => {
    return (
        <>
        <Header/>
      <main className={styled.main}>
        <CarouselContainer/>
      </main>
      
        <Footer/>
      
        </>
    )
}
