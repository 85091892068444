import React from 'react';
import styled from 'styled-components'
import { Title } from '../header/Title';
import { Bruger } from './Bruger';

const Nav = styled.div`
    width: 100%;
    height: 105px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;   
`


export const NavBar = () => {




    return (
        
        <Nav>
            <Title/>
            <Bruger/>
       </Nav>
        
    )
}
