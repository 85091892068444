import React from 'react'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header';
import styled from './curso.module.css'
import {Link} from 'react-router-dom';
import aca  from '../imgs/carousel5.jpg'

export const CursAcadeScreen = () => {
    return (
        <> 
         <Header/>
         <div className={styled.container}>
             <img className={styled.img} src={aca} alt="acade"/>
             <div className={styled.description}>
                 <h1 className={styled.title}>English for academic purposes.</h1>
             <h3 className={styled.about}>Preparación para rendir examen de acreditación de nivel de idioma inglés. Varias carreras universitarias requieren que rindas un examen de acreditación de nivel de
idioma inglés.</h3>
            <div className={styled.especifications} >      <h3>Modalidad: Online.</h3> 
            <h3>Horarios: Adaptados a tus posibilidades.</h3>
            <h3>Tarifas: Consultar. Aceptamos tarjetas de crédito y débito.</h3>
            <h3><Link to="/contact">Contactanos.</Link></h3>   
              
             </div>
            
             </div>
             
         </div>
         <Footer/>   
        </>
    )
}
