import React from 'react'
import styled from './CardCursos.module.css'
import {Link} from 'react-router-dom'
import engineer from '../imgs/carousel1-m.jpg'
import school from '../imgs/carousel3-min.jpg'
import vol from '../imgs/carousel2.png'
import conv from '../imgs/carousel4-min.jpg'
import aca  from '../imgs/carousel5.jpg'
 
export const CardsCursos = () => {
    return (
        
<div className={styled.wrapper}>
    <div className={styled.card}><img src={engineer} alt="curse" className={styled.img}/>
        <div className={styled.info}>
            <h1 className={styled.h1}>Engineers.</h1>
            <p className={styled.p}>Podrás desarrollar habilidades de escritura en inglés, de textos específicos tales como reportes, formularios y mucho mas.</p><button className={styled.button}><Link className={styled.link} to="/cursos/engineer">Leer mas.</Link></button>
        </div>
    </div>
    <div className={styled.card}><img src={vol} alt="curse" className={styled.img}/>
        <div className={styled.info}>
            <h1 className={styled.h1}>Volunteers.</h1>
            <p className={styled.p}>Te preparamos para rendir tu examen de inglés, en las áreas de: Writing, Listening, Reading, Speaking.</p><button className={styled.button}><Link className={styled.link} to="/cursos/volunteers">Leer mas.</Link></button>
        </div>
    </div>
    <div className={styled.card}><img src={school} alt="curse" className={styled.img}/>
        <div className={styled.info}>
            <h1 className={styled.h1}>Primary and secondary.</h1>
            <p className={styled.p}>Apoyo académico nivel primario y secundario, modalidad online y/ o presencial.</p><button className={styled.button}><Link className={styled.link} to="/cursos/school">Leer mas.</Link></button>
        </div>
    </div>
    <div className={styled.card}><img src={conv} alt="curse" className={styled.img}/>
        <div className={styled.info}>
            <h1 className={styled.h1}>Conversation.</h1>
            <p className={styled.p}>Mejorando tu habilidad auditiva, podrás expresarte con más confianza y fluidez.</p><button className={styled.button}><Link className={styled.link} to="/cursos/conversation">Leer mas.</Link></button>
        </div>
    </div>
    <div className={styled.card}><img src={aca} alt="curse" className={styled.img}/>
        <div className={styled.info}>
            <h1 className={styled.h1}>Academic.</h1>
            <p className={styled.p}>Preparación para rendir examen de acreditación de nivel de idioma inglés.</p><button className={styled.button}><Link className={styled.link} to="/cursos/academic">Leer mas.</Link></button>
        </div>
    </div>

</div>

    )
}
