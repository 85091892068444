import React from 'react'
import { About } from '../about/About'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'

export const AboutScreen = () => {
    return (
        <>
           <Header/>
           <About/>
           <Footer/>
        </>
    )
}
