import React from 'react'
import styled from './about.module.css'
import aboutImg from '../imgs/about-min.jpg'

export const About = () => {
    return (
        <div className={styled.container}>
            <img src={aboutImg} alt="Nosotros"/>
            <div className={styled.description}>
               <div className={styled.descriptionPrimero}>
           <h1>NUESTRA EMPRESA</h1>

        <h3>A&amp;L English Institute</h3> 
            </div>  
        
        <div className={styled.descriptionSegundo}>
        <h4>A&amp;L es una institución educativa nueva, creada en un contexto acorde a los requerimientos actuales de distanciamiento social y de continuidad
académica virtual.</h4>
<h4>Nuestro objetivo es la enseñanza del idioma inglés, ofreciendo cursos y talleres adaptados a la necesidad y disponibilidad horaria de nuestros
alumnos. A&amp;L English Institute posee metodología propia y material de estudio específico.</h4>

</div> 
            </div>
        
</div>
    )
}
