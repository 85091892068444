import React from 'react'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
import styled from './curso.module.css'
import {Link} from 'react-router-dom';
import engineer from '../imgs/carousel1-m.jpg'



export const CursEngiScreen = () => {
    return (
        <> 
         <Header/>
         <div className={styled.container}>
             <img className={styled.img} src={engineer} alt=""/>
             <div className={styled.description}>
                 <h1 className={styled.title}>English for aircraft engineers and mechanics.</h1>
             <h3 className={styled.about}>Aprendé a escribir y expresarte como profesional aeronáutico. Podrás desarrollar habilidades de
escritura en inglés, de textos específicos tales como reportes, formularios, informes de inspección, resúmenes y artículos de publicación.</h3>
            <div className={styled.especifications} >    <h3>Duración: 3 meses.</h3> <h3>Inicio: 7 abril 2021.</h3> <h3>Modalidad: Online.</h3> 
            <h3>Horarios: Miércoles 20.30 a 21.30 hs. y Sábados 14 a 15 hs.</h3>
            <h3>Tarifas: $ 2000 por mes. Aceptamos tarjetas de crédito y débito.</h3>
            <h3>Se otorga certificado de cursado con duración de 25 horas reloj.</h3>
            <h3><Link to="/contact">Contactanos.</Link></h3>  
             </div>
            
             </div>
             
         </div>
         <Footer/>   
        </>
    )
}
