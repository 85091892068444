import React from 'react'
import { CardsCursos } from '../cursos/CardsCursos'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'


export const CursosScreen = () => {
    return (
        <>
            <Header/>
            <CardsCursos/>
            <Footer/>
        </>
    )
}
