import React from 'react'
import { Logo } from './Logo'
import styled from './header.module.css';


export const Title = () => {
    return (
        <div className={styled.header__title}>
        <Logo className={styled.header__logo}/>
         <h3>A&L ENGLISH INSTITUTE </h3>   
        </div>
    )
}
