import React, { useState } from 'react'
import styled from 'styled-components'
import { SideBarData } from './SideBarData'

const StyledBurger = styled.div`
    width: 3rem;
    height: 3rem;
    position: relative;
    top: 25px;
    right:20px;
    display:none;
    z-index: 20;
    position:fixed;
    @media screen and (max-width: 835px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    }

  

    div{
        width: 3rem;
        height: 0.4rem;
        background-color: #ff7b00;
        border-radius: 10px;
        transform-origin: 1px;
        transition: transform 0.3s; 

        &:nth-child(1){
            transform: ${({open}) => open ? 'rotate(45deg)' : 'rotete(0deg)'};
        }
        &:nth-child(2){
            transform: ${({open}) => open ? 'translateX(100%)' : 'translateX(100)'};
            opacity:  ${({open}) => open ? '0' : '1'};
        }
        &:nth-child(3){
            transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotete(0deg)'};
        }
    }
`


export const Bruger = () => {
    const [open, setOpen] = useState(false)
    return (
        <>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div/>
            <div/>
            <div/>
        </StyledBurger>
        <SideBarData open={open}/>
        </>  
    )
}
