import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppRouter } from './components/routers/AppRouter';



export const App = () => {
  return (
    <>
      <AppRouter/>
    </>
  )
}

export const AAA = () =>  {
  return (
    <>
    <div></div>
    </>

  )
}