import React from 'react'
import styled from './footer.module.css';
import {Link} from 'react-router-dom';
export const Footer = () => {
    return (
        <footer className={styled.footer}>
            <div className={styled.footer__about}>
                <h3 className={styled.h3}>Nosotros▶</h3>
                <h5 className={styled.h5__about}>A&amp;L es una institución educativa nueva, creada en un contexto acorde a los requerimientos actuales de distanciamiento social y de continuidad académica virtual.</h5>
            </div>
            <div className={styled.footer__about}>
                <h3 className={styled.h3}>Contacto▶</h3>
                <ol>
                    <li className={styled.li}>  <i className="fas fa-map-marker-alt"></i><h5 className={styled.h5}>Av O’Higgins 8000, Córdoba Capital</h5></li>
                    <li className={styled.li}><i className="fas fa-mobile-alt"></i><h5 className={styled.h5}>3517520324</h5></li>
                    <li className={styled.li}><i className="far fa-envelope"></i> <h5 className={styled.h5}> aandlenglishinstitute@gmail.com</h5></li>
                </ol>
            </div>
            <div className={styled.footer__about}>
            <h3 className={styled.h3}>Cursos▶</h3>
                <ol className={styled.ol__cur}>
                    <li className={styled.li__cur}><h5 className={styled.h5__cur}><Link to="/cursos/engineer">English for aircraft engineers and mechanics.</Link></h5></li>
                    <li className={styled.li__cur}><h5 className={styled.h5__cur}><Link to="/cursos/volunteers">English for UN volunteers.</Link></h5></li>
                    <li className={styled.li__cur}><h5 className={styled.h5__cur}><Link to="/cursos/school">English for primary and secondary school.</Link></h5></li>
                    <li className={styled.li__cur}><h5 className={styled.h5__cur}><Link to="/cursos/conversation">Part time conversation course.</Link></h5></li>
                    <li className={styled.li__cur}><h5 className={styled.h5__cur}><Link to="/cursos/academic">English for academic purposes.</Link></h5></li>
                </ol>
            </div>
        </footer>
    )
}
