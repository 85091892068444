import React from "react";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import styled from "./curso.module.css";
import { Link } from "react-router-dom";
import conv from "../imgs/carousel4-min.jpg";
// import prom from '../imgs/promo.png'

export const CursConverScreen = () => {
  return (
    <>
      <Header />
      <div className={styled.container}>
        <img className={styled.img} src={conv} alt="" />
        <div className={styled.description}>
          {/* <img className={styled.promo} src={prom} alt="promo"/> */}
          <h1 className={styled.title}>Part time conversation course.</h1>
          <h3 className={styled.about}>
            Aprendé inglés interactuando con otras personas. Mejorando tu
            habilidad auditiva, podrás expresarte con más confianza y fluidez.
            Cursos dinámicos y entretenidos.
          </h3>
          <div className={styled.especifications}>
            {" "}
            <h3>Duración: 3 meses.</h3> <h3>Inicio: 5 abril 2021.</h3>{" "}
            <h3>Modalidad: Online.</h3>
            <h3>
              Horarios: Clases personalizadas y adaptadas a los horarios de los
              alumnos.
            </h3>
            <h3>Tarifas: Consultar. Aceptamos tarjetas de crédito y débito.</h3>
            <h3>
              Se otorga certificado de cursado con duración de 25 horas reloj.
            </h3>
            <h3>
              <Link to="/contact">Contactanos.</Link>
            </h3>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
