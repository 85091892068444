import React from 'react'
import styled from './header.module.css'
import logo from '../imgs/logo.png'
export const Logo = () => {
    return (
        <>
            <img className={styled.imgLogo} alt="LOGO" src={logo}/>
        </>
    )
}
