import React from 'react'
import styled from './header.module.css'
import { NavBar } from '../burgerMenu/NavBar'

export const Header = () => {
    return (
        <header className={styled.header}>
        <NavBar/>
      </header>
    )
}
