import React from 'react'
import emailjs from 'emailjs-com';
import styled from './form.module.css'


export const Form = () => {


 const  sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('erica', 'template_5bdllmf', e.target, 'user_fWnPiXjyQC6EzLYYlonKY')
      .then((result) => {
          alert(result.text = 'Mensaje enviado');
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
      <>
      <div className={styled.title__container}>
          <div className={styled.title}>
                   <h2>OBTENÉ INFORMACIÓN</h2>
      <h5>Completa el formulario y recibí información completa sobre Nuestros Cursos.</h5>
          </div>
      </div>
     
    <form className={styled.form} onSubmit={sendEmail}>
      <input required className={styled.input}  type="hidden" name="name" />
      <label className={styled.label} >Nombre</label>
      <input required className={styled.input}  type="text" name="name" />
      <label className={styled.label}>Email</label>
      <input required className={styled.input}  type="email" name="email"/>
      <label className={styled.label}>Asunto</label>
      <input required className={styled.input}  type="text" name="subject" />
      <label className={styled.label}>Mensaje</label>
      <textarea rows="6" name="message" />
      <input className={`${styled.input} ${styled.button}`}  type="submit" value="Enviar Email"/>
    </form>
    </>
  );
}

