import React from 'react'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
import styled from './curso.module.css'
import {Link} from 'react-router-dom';
import vol from '../imgs/carousel2.png'


export const CursVolScreen = () => {
    return (
        <> 
         <Header/>
         <div className={styled.container}>
             <img className={styled.img} src={vol} alt=""/>
             <div className={styled.description}>
                 <h1 className={styled.title}>English for UN volunteers.</h1>
             <h3 className={styled.about}>Te preparamos para rendir tu exámen de inglés, en las áreas de: Writing,
Listening,
Reading,
Speaking.</h3>
            <div className={styled.especifications} > <h3>Modalidad: Online y/o Presencial.</h3> 
            <h3>Horarios: Adaptados a tus posibilidades.</h3>
            <h3>Tarifas: Consultar. Aceptamos tarjetas de crédito y débito.</h3> 
            <h3><Link to="/contact">Contactanos.</Link></h3> 
             </div>
            
             </div>
             
         </div>
         <Footer/>   
        </>
    )
}
