import React from 'react';
import { Header } from '../header/Header';
import {Link} from 'react-router-dom';
import styled from './comp404.module.css'


export const Comp404Screen = () => {
    return (
        <div>
            <Header/>
            <div className={styled.container}>
                <div className={styled.container__title}>
                    <h1 className={styled.icon}>⛔</h1>
                    <h1 className={styled.h1}>
                    Upss. Algo salio mal.
                </h1>
                <h3 className={styled.h1}>
                   Pagina no encontrada, por favor verifica la URL ingrezada o vuelve a <Link to="/aylenglishintitute/"> Inicio </Link>. Muchas Gracias.
                </h3>
                </div>
                
            </div>
        </div>
    )
}
