import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { CursosScreen } from './CursosScreen';
import { AboutScreen } from './AboutScreen';
import { InicioScreen } from './InicioScreen';
import { ContactScreen } from './ContactScreen';
import { Comp404Screen } from '../comp404/Comp404Screen';
import { CursEngiScreen } from '../curso/CursEngiScreen';
import { CursVolScreen } from '../curso/CursVolScreen';
import { CursSchoolScreen } from '../curso/CursSchoolScreen';
import { CursConverScreen } from '../curso/CursConverScreen';
import { CursAcadeScreen } from '../curso/CursAcadeScreen';
import ScrollToTop from './ScrollToTop';




export const AppRouter = () => {
    return (
        <Router>

            <div>
            <ScrollToTop/>
                <Switch>
                    {/* <Route exact  path="/" component={ InicioScreen }/> */}
                    <Route exact  path="/" component={ InicioScreen }/>
                    <Route exact path="/cursos" component={ CursosScreen }/>
                    <Route exact path="/about" component={ AboutScreen }/>
                    <Route exact path="/contact" component={ ContactScreen }/>
                    <Route exact path="/cursos/engineer" component={ CursEngiScreen }/>
                    <Route exact path="/cursos/volunteers" component={ CursVolScreen }/>
                    <Route exact path="/cursos/school" component={ CursSchoolScreen }/>
                    <Route exact path="/cursos/conversation" component={ CursConverScreen }/>
                    <Route exact path="/cursos/academic" component={ CursAcadeScreen }/>
                    <Route component={ Comp404Screen }/>
                </Switch>
            </div>
        </Router>
    )
}
