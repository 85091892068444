import React from "react";
import { Carousel } from "react-bootstrap";
import styled from "./carousele.module.css";
import { Link } from "react-router-dom";
import "./carousel.css";
import engineer from "../imgs/carousel1-m.jpg";
import school from "../imgs/carousel3-min.jpg";
import vol from "../imgs/carousel2.png";
import conv from "../imgs/carousel4-min.jpg";
import aca from "../imgs/carousel5.jpg";

export const CarouselContainer = () => {
  return (
    <Carousel className={styled.carousel}>
      <Carousel.Item interval={3000}>
        <img className={styled.carousel} src={school} alt="Third slide" />
        <Carousel.Caption className={styled.carouselBack}>
          <h1>
            <Link to="/cursos/school">
              English for primary and secondary school.
            </Link>
          </h1>
          <h2>
            Apoyo académico nivel primario y secundario, modalidad online y/ o
            presencial.
          </h2>
          <h4>Aceptamos tarjetas de crédito y débito.</h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className={styled.carousel} interval={3000}>
        <img className={styled.carousel} src={engineer} alt="First slide" />
        <Carousel.Caption className={styled.carouselBack}>
          <h1>
            <Link to="/cursos/engineer">
              English for aircraft engineers and mechanics.
            </Link>
          </h1>
          <h2>
            Podrás desarrollar habilidades de escritura en inglés, de textos
            específicos tales como reportes, formularios y mucho mas.
          </h2>
          <h4>Aceptamos tarjetas de crédito y débito.</h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className={styled.carousel} src={vol} alt="Second slide" />
        <Carousel.Caption className={styled.carouselBack}>
          <h1>
            <Link to="/cursos/volunteers">English for UN volunteers.</Link>
          </h1>
          <h2>
            Te preparamos para rendir tu examen de inglés, en las áreas de:
            Writing, Listening, Reading, Speaking.
          </h2>
          <h4>Aceptamos tarjetas de crédito y débito.</h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className={styled.carousel} src={conv} alt="Third slide" />
        <Carousel.Caption className={styled.carouselBack}>
          <h1>
            <Link to="/cursos/conversation">Conversation course.</Link>
          </h1>
          <h2>
            Mejorando tu habilidad auditiva, podrás expresarte con más confianza
            y fluidez.
          </h2>
          <h4>Aceptamos tarjetas de crédito y débito.</h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className={styled.carousel} src={aca} alt="Third slide" />
        <Carousel.Caption className={styled.carouselBack}>
          <h1>
            <Link to="/cursos/academic">English for academic purposes.</Link>
          </h1>
          <h2>
            Preparación para rendir examen de acreditación de nivel de idioma
            inglés.
          </h2>
          <h4>Aceptamos tarjetas de crédito y débito.</h4>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
